<template>
	<div class="card">
		<div class="card-body">
			<bankingInstitutionsListComponent />
		</div>
	</div>
</template>

<script>
import bankingInstitutionsListComponent from '@/components/bankingInstitutions/list'

export default {
	name: 'bankingInstitutionsView',
	components: {
		bankingInstitutionsListComponent,
	},
}
</script>