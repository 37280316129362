<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-12 text-right">
					<a-button class="btn btn-info" icon="plus" @click="onAddRecord">{{ $t('general.add') }}</a-button>
					<a-button class="btn btn-success ml5" icon="reload" @click="initModule" />
				</div>
			</div>
			<div class="row pt10">
				<div class="col-md-12">
					<a-input allowClear v-model="searchText" @change="onSearch" placeholder="Búsqueda en Instituciones Bancarias..." />
				</div>
				<div class="col-md-12 pt10">
					<a-table :columns="columns" :dataSource="searchText == '' ? bankingInstitutionsList : bankingInstitutionsListFiltered">
						<span slot="action" slot-scope="record">
							<a-button type="info" size="small" icon="edit" @click="onEditRecord(record.id)" />
							<a-button class="ml5" size="small" icon="minus-circle" @click="onToggleRecord(record.id)" />
						</span>
					</a-table>
				</div>
			</div>
		</a-spin>
		<bankingInstitutionsDetailComponent :visible="modal.visible" :title="modal.title" @close="onCloseModal" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import bankingInstitutionsDetailComponent from '@/components/bankingInstitutions/detail'

export default {
	name: 'bankingInstitutionsListComponent',
	components: {
		bankingInstitutionsDetailComponent,
	},
	computed: {
		...mapGetters('bankingInstitutions', ['spinnerLoader', 'spinnerLoaderLabel', 'bankingInstitutionsList']),
		columns() {
			let columns = [
				{
					title: this.$t('catalogsModule.bakingInstitutions.tableHeaders.id'),
					dataIndex: 'code',
					align: 'center',
				},
				{
					title: this.$t('catalogsModule.bakingInstitutions.tableHeaders.shortName'),
					dataIndex: 'short_name',
				},
				{
					title: this.$t('catalogsModule.bakingInstitutions.tableHeaders.institutionName'),
					dataIndex: 'bank_name',
				},
				{
					title: this.$t('general.actions'),
					scopedSlots: { customRender: 'action' },
					align: 'right',
				},
			]
			return columns
		},
	},
	data() {
		return {
			searchText: '',
			bankingInstitutionsListFiltered: [],
			modal: {
				visible: false,
				title: this.$t('catalogsModule.bakingInstitutions.add.title'),
				payload: {},
			},
		}
	},
	mounted() {
		if (this.bankingInstitutionsList.length == 0) {
			this.initModule()
		}
	},
	methods: {
		initModule() {
			this.$store.dispatch('bankingInstitutions/GET')
		},
		onAddRecord() {
			this.modal = {
				visible: true,
				title: this.$t('catalogsModule.bakingInstitutions.add.title'),
			}
		},
		onEditRecord(id) {
			this.$store.dispatch('bankingInstitutions/GET_ONE', id)
			this.modal = {
				visible: true,
				title: this.$t('catalogsModule.bakingInstitutions.edit.title'),
			}
		},
		onCloseModal() {
			this.modal = {
				visible: false,
			}
			this.$store.commit('bankingInstitutions/SET_STATE', {
				actualRecord: {},
			})
		},
		onToggleRecord(id) {
			Swal.fire({
				title: this.$t('general.deleteMessage.title'),
				html: this.$t('general.deleteMessage.message'),
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: this.$t('general.yesContinue'),
				cancelButtonText: this.$t('general.cancel'),
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('bankingInstitutions/TOGGLE', id)
				}
			})
		},
		onSearch(event) {
			let { value } = event.target
			this.bankingInstitutionsListFiltered = []
			if (value != '') {
				this.bankingInstitutionsListFiltered = this.bankingInstitutionsList.filter((obj) =>
					Object.values(obj).some((val) => {
						if (typeof val == 'string' && val.toLowerCase().includes(value.toLowerCase())) {
							return true
						}
					}),
				)
			}
		},
	},
}
</script>