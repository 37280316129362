var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"width":"50%","visible":_vm.visible,"title":_vm.title,"closable":false}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.$t('general.close'))+" ")]),_c('a-button',{key:"submit",staticClass:"btn btn-success",on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.$t('general.save'))+" ")])],1),_c('a-spin',{attrs:{"tip":_vm.spinnerLoaderLabel,"size":"small","spinning":_vm.spinnerLoader}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"label":_vm.$t('catalogsModule.bakingInstitutions.edit.code')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'code',
								{
									rules: _vm.requiredFileds,
								},
							]),expression:"[\n\t\t\t\t\t\t\t\t'code',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trules: requiredFileds,\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"label":_vm.$t('catalogsModule.bakingInstitutions.tableHeaders.shortName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'short_name',
								{
									rules: _vm.requiredFileds,
								},
							]),expression:"[\n\t\t\t\t\t\t\t\t'short_name',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trules: requiredFileds,\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('a-form-item',{attrs:{"label":_vm.$t('catalogsModule.bakingInstitutions.tableHeaders.institutionName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'bank_name',
								{
									rules: _vm.requiredFileds,
								},
							]),expression:"[\n\t\t\t\t\t\t\t\t'bank_name',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trules: requiredFileds,\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1)])])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }