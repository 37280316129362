<template>
	<a-modal width="50%" :visible="visible" :title="title" :closable="false">
		<template slot="footer">
			<a-button key="back" @click="onCancel"> {{ $t('general.close') }} </a-button>
			<a-button key="submit" class="btn btn-success" @click="handleSubmit"> {{ $t('general.save') }} </a-button>
		</template>
		<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
			<a-form layout="vertical" :form="form" @submit="handleSubmit">
				<div class="row">
					<div class="col-md-6">
						<a-form-item :label="$t('catalogsModule.bakingInstitutions.edit.code')">
							<a-input
								v-decorator="[
									'code',
									{
										rules: requiredFileds,
									},
								]"
								autocomplete="off"
							/>
						</a-form-item>
					</div>
					<div class="col-md-6">
						<a-form-item :label="$t('catalogsModule.bakingInstitutions.tableHeaders.shortName')">
							<a-input
								v-decorator="[
									'short_name',
									{
										rules: requiredFileds,
									},
								]"
								autocomplete="off"
							/>
						</a-form-item>
					</div>
					<div class="col-md-12">
						<a-form-item :label="$t('catalogsModule.bakingInstitutions.tableHeaders.institutionName')">
							<a-input
								v-decorator="[
									'bank_name',
									{
										rules: requiredFileds,
									},
								]"
								autocomplete="off"
							/>
						</a-form-item>
					</div>
				</div>
			</a-form>
		</a-spin>
	</a-modal>
</template>
<script>
//
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'

export default {
	name: 'bankingInstitutionsDetailComponent',
	components: {},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: '',
		},
	},
	computed: {
		...mapGetters('bankingInstitutions', ['spinnerLoader', 'spinnerLoaderLabel', 'actualRecord']),
		requiredFileds() {
			let rules = [
				{
					required: true,
					message: this.$t('general.requiredField'),
				},
			]
			return rules
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
		}
	},
	methods: {
		onCancel() {
			this.$emit('close')
			this.form.resetFields()
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				if (!err) {
					//
					let payload = {
						...values,
					}
					if (utilities.objectValidate(this.actualRecord, 'id', false)) {
						payload.id = this.actualRecord.id
						await this.$store.dispatch('bankingInstitutions/UPDATE', payload)
					} else {
						await this.$store.dispatch('bankingInstitutions/CREATE', payload)
					}
					this.onCancel()
				}
			})
		},
	},
	watch: {
		actualRecord: {
			deep: true,
			handler(newValue) {
				if (utilities.objectValidate(newValue, 'id', false)) {
					this.form.setFieldsValue({
						code: utilities.objectValidate(newValue, 'code', ''),
						short_name: utilities.objectValidate(newValue, 'short_name', ''),
						bank_name: utilities.objectValidate(newValue, 'bank_name', ''),
					})
				}
			},
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>